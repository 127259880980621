
/* Styles généraux */
.home-container {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 0px;
    box-sizing: border-box;
    margin: 0 auto;
}

.acceuil, .section1, .section2, .section3, .section5 {
    background-color: rgb(7, 7, 39) ;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.acceuil {
    height: 750px;
    
}
  
.sectiona2{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:white;
    
}
.sectiona3{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:white;
    
}

/*la section photo et text*/
.sectiona1{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:rgb(229, 226, 226);
}

.sectiona3-text,
.sectiona1-text,
.sectiona2-text{
   color: black;
    padding: 2rem;
    width: 100%;
    text-align: left;
    margin-bottom: 80px;
    line-height: 2;
    font-size: 18px;
}

.sectiona1-img{
    width: 100%;
    margin-bottom: 50px;
}

.sectiona8 {
    background-color:rgb(11, 10, 10);
    width: 95%;
    overflow: hidden;
    margin-top: 20px;
  }
  
  .image-slider {
    animation: slide 10s linear infinite;
    scroll-behavior: smooth;
    display: flex; /* Affichage en ligne des images */
    gap: 10px; /* Espace entre les images */
  }
  .image-slider img {
    width: auto; /* Largeur automatique pour maintenir les proportions */
    height: 100px; /* Hauteur fixe ou ajustable selon le besoin */
    object-fit: contain; /* Assure que l'image est entièrement visible */
}
  .section4 img {
    flex: 0 0 auto;
    max-width: calc(25% - 10px);
    height: 350px;
    margin-right: 10px;
    object-fit: cover;
  }
  
  @keyframes slide {
    from { transform: translate3d(0, 0, 0); }
    to { transform: translate3d(-50%, 0, 0); } /* Assurez-vous que cela correspond à la largeur totale des images dupliquées */

  }
  
/* Styles responsive pour les écrans plus petits */
@media (max-width: 768px) {
    .home-container {
        padding: 10px;
    }

    /*pour le defilement
    .section4 img {
        width: 100%;
        margin-right: 0;
    }
*/
    .sectiona2,
    .sectiona3,
    .sectiona1 {
        flex-direction: column;  
    }

    .sectiona1-img
    .sectiona2-img{
        margin-bottom: 10px;
    }
    
    .sectiona3-text,
    .sectiona1-text,
    .sectiona2-text{
        padding: 0.5rem;
        margin-bottom: 15px; 
    }   
}