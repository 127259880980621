.kontakt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kontakt-section1 {
  background: url('image001.webp') no-repeat center center/cover;
  color: #fff;
  text-align: center;
  padding: 100px 20px;
  width: 100%;
}

.kontakt-text h1, .kontakt-text h2, .kontakt-text p {
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
}

.kontakt-section2 {
  padding: 40px 20px;
  width: 100%;
}

.kontakt-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.kontakt-form input, .kontakt-form textarea, .kontakt-form button {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.kontakt-form textarea {
  resize: vertical;
  min-height: 120px;
  width: 100%;
}

.kontakt-form button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.kontakt-form button:hover {
  background-color: #45a049;
}
