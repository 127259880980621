

/* Styles pour la page d acceuill */
.Management-page {
    font-family: Arial, sans-serif;
    padding: 0px;
    background-color: rgb(14, 9, 9);
    box-sizing: border-box;
    background-size: cover;

}

