.app-header {
  background-color: rgb(17, 26, 43);
  padding: 1px 0;
  color: rgb(7, 165, 139);
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-image {
  margin-left: 30px;
  height: auto; /* Adjust height automatically */
    max-width: 100px; /* Set maximum width for the logo image */
    width: auto; /* Adjust width automatically */
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}

nav ul li {
  position: relative;
}

nav ul li a, nav ul li span {
  color:white;
  display: inline-block;
  padding: 10px 25px;
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none;
  font-size: 20px;
}

nav ul li a:hover, nav ul li span:hover {
  background-color: greenyellow;
}

nav ul .dropdown {
  display: none;
  position: absolute;
  background: #000;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: -10px;
 
  top: auto;

  z-index: 1;
}

nav ul li:hover .dropdown {
  display: block;
}

nav ul .dropdown.show {
  display: block;
}

.menu-toggle {
  display: none;
  background: black;
  border: none;
  color: white;
  font-size: 40px;
  cursor: pointer;
}

.menu-toggle.open {
  display: none;
}

@media only screen and (max-width: 768px) {
  .app-header {
    flex-direction: column;
    padding: 10px 0;
    color: rgb(7, 165, 139);
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  

  nav {
    display: none;
      width: 100%;
      background-color: rgb(34, 31, 31);
      
      
  }

  nav.active {
      display: block;
  }
  nav ul li a, nav ul li span {
    font-size: 16px; /* Adjust font size for smaller screens */
    padding: 8px 20px; /* Adjust padding for smaller screens */
  }
  nav ul {
    flex-direction: column;
    width: 100%; /* Full width */
    align-items: center; /* Center align the nav items */
      gap: 10px;
      
      
  }
  nav ul .dropdown {
    position: static; /* Use static instead of absolute */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding in width calculation */
      background: transparent;
      border: none;
      
  }

  .menu-toggle {
      display: block;
  }
  .menu-toggle.open {
    display: block; /* Ne pas afficher le bouton de bascule lorsqu'il est ouvert */

  }
}
