.test-container {
  width: 100%;
}

/* Styles pour le carrousel */
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
 
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
  color: white;
}

.prev-button:hover,
.next-button:hover {
  color: #0056b3;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 800px;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: auto; /* Ajustez la hauteur selon vos besoins */
}

.caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: blue;
  padding: 20px;
  text-align: center;
}



/* Styles pour les séparations inclinées dans les sections */
.section::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
  background: inherit;
  transform: skewY(-60deg);
  transform-origin: top left;
}

/* Ajustements pour les petits écrans */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  

}

.reference-section {
  position: relative;
  background-color: #bba8a8;
  padding: 100px; /* Vous pouvez augmenter la valeur du padding pour augmenter la hauteur. */
  color: #000;
  text-align: left;
  transform: skewY(-4deg);
  background: linear-gradient(to left bottom, transparent 10%, #4967a0 );
  transform-origin: bottom left;
  z-index: -1;
  min-height: 100px; /* Ajoutez ou modifiez cette valeur pour obtenir la hauteur désirée. */
}

.reference-section::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1200px;
  height: 350px; /* Augmentez également cette valeur si vous voulez que l'inclinaison aille plus haut. */
  background: linear-gradient(to left bottom, transparent 20%, white 80%);
  transform: skewY(-2deg);
  transform-origin: bottom left;
  z-index: -1;
}

.reference-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.reference-section p {
  font-size: 1rem;
  line-height: 1.6;
}


.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.track {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
}

/* Taille des images */
.track img {
  height: 100px; /* ou la hauteur désirée */
  margin: 0 10px; /* espace entre les images */
}


