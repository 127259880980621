/* Variables */
:root {
    --footer-column-gap: 30px; /* Espacement entre les colonnes */
}

/* Styles de base pour le footer */
.footer1 {
    background-color: rgb(17, 26, 43);
    padding: 20px 0;
    color: white;
    font-family: Arial, sans-serif;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

/* Contenu du footer */
.footer-content1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    max-width: 1200px;
    margin: 0 auto;
    gap: var(--footer-column-gap);
    flex-wrap: wrap; /* Permet le passage à la ligne sur des écrans plus petits */
}

/* Sections du footer */
.footer-section1 {
    display: flex;
    flex-direction: column;
    line-height: 2.5;
    gap: 15px;
    flex: 1; /* Flexibilité pour occuper l'espace disponible */
    min-width: 200px; /* Largeur minimale pour éviter une compression excessive */
}

/* Styles pour les titres et les spans dans les sections du footer */
.footer-section h4 {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: normal;
}

.footer-section1 span {
    font-size: 15px;
}
.social-media-section1 .fa-facebook {
    font-size: 24px; /* Or your desired size */
    color: #ffffff; /* Facebook color */
    margin-right: 10px;
  }
  
  .social-media-section1 a {
    display: inline-block;
    margin-top: 10px;
  }
  
  /* You may add :hover and :focus styles for better user experience */
  .social-media-section1 a:hover,
  .social-media-section1 a:focus {
    text-decoration: none;
    opacity: 0.8;
  }

  .backlink{
    
    text-decoration-line: underline;
    padding: 20px;
    font-size: 15px;
    margin-top: 350px;
    line-height: 2.5;
    background-color: white;
  }

/* Media Queries */

/* Écrans moyens (tablettes) */
@media only screen and (max-width: 768px) {
    .footer-section1,
    .footer1 {
        flex-direction: column;
        flex-basis: 50%; /* Prend 50% de la largeur de l'écran */
    }
    .social-media-section1 {
        flex-direction: column;
        margin-left: 15px;
        gap: 20px;
      }
      .footer-section1 {
        flex-basis: 100%; /* Prend toute la largeur de l'écran */
        margin-left: 15px;
    }
}

/* Écrans petits (mobiles) */
@media only screen and (max-width: 480px) {
    .footer-content1 {
        flex-direction: column;
        gap: 20px; /* Augmente l'espacement */
    }
    .footer1{
        overflow: hidden;
    }
    .footer-section1 {
        flex-basis: 100%; /* Prend toute la largeur de l'écran */
        margin-bottom: 15px;
    }
    
}

/* Écrans très larges */
@media only screen and (min-width: 2001px) {
    :root {
        --footer-column-gap: 20px; /* Augmente l'espacement entre les colonnes */
    }
    .footer-content1 {
        padding: 0 30px;
        max-width: none;
    }  
}
