.Pentesting-page {
  font-family: Arial, sans-serif;
  max-width: 100%;
}

.section {
  padding: 20px;
  margin-bottom: 20px;
  height: auto; /* Utiliser 'auto' ou 'min-height' pour la hauteur flexible */
  min-height: 400px;
}

.background-section-Pentesting {
  background-color: #007bff;
  color: #fff;
}

.overlay-text1 {
  text-align: center;

}

.rectangle02,
.rectangle02.reversed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgb(12, 3, 3);
  margin: 80px 5%; /* Utilisez des pourcentages pour les marges latérales */
  flex-direction: row-reverse;
  margin-top: 80px ;
  
  overflow-x: hidden;
 
  
}

.text-container {
  max-width: 50%;
}


.white-sectiona1 {
  background-color: rgb(7, 7, 39);;
 height: 800px !important;
 padding-top: 80px;
}

.boxa1 {
  margin-bottom: 50px;
  background-color: aquamarine;
  border-radius: 60px !important;
 max-width: 95%;
  overflow: hidden;
  margin-left: 30px;
}
.boxa2 {
  background-color: blanchedalmond;
  margin-bottom: 50px;
  border-radius: 60px;
  overflow: hidden;
  max-width: 95%;
  margin-left: 30px;
 
}
.boxa3 {
  overflow: hidden;
  background-color: azure;
  margin-bottom: 50px;
  border-radius: 60px;
  max-width: 95%;
  margin-left: 30px;
  
}
.boxa3:hover {
  transform: scale(1.05); /* Agrandit l'image de 5% */
  transition: transform 0.3s ease-in-out; /* Animation fluide */
}
.boxa2:hover {
  transform: scale(1.05); /* Agrandit l'image de 5% */
  transition: transform 0.3s ease-in-out; /* Animation fluide */
}
.boxa1:hover {
  transform: scale(1.05); /* Agrandit l'image de 5% */
  transition: transform 0.3s ease-in-out; /* Animation fluide */
}


.titlea1 {
  font-size: 1.5rem; /* Utiliser 'rem' pour la taille de police */
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #007bff;
}
.texta1 {
  font-size: 1rem; /* Utiliser 'rem' pour la taille de police */
  line-height: 1.6;
  color: black;
  margin-top: 20px;
  margin: 20px;
}


.title1, .title2, .title3, .title4, .title5 {
  font-size: 1.5rem; /* Utiliser 'rem' pour la taille de police */
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #007bff;

}


.hero-section {
  height: 100vh; /* Utiliser 'vh' pour que la hauteur prenne tout l'écran */
  width: 100%;
 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.hero-title {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.sous-titre {
  color: white;
  position: absolute;
  left: 50%;
  top: calc(65% + 3rem); /* Déplacer vers le bas par rapport au titre */
  transform: translate(-50%, -50%);
  font-size: 2rem;
  transition: transform 0.5s ease;
}

.image-container img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  overflow-x: hidden;
  
}
 .Box:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Ajoute une ombre */
  transition: box-shadow 0.3s ease-in-out; /* Animation fluide */
}

.circle {
  background-color: #fff;
  color: #007bff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  margin-bottom: 10px;
}

.statistics-container {
  text-align: center;
  background-color: #007bff;
  color: #fff;
  padding: 40px 20px;
}

.stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles pour les étapes de processus */
.penetration-testing-section {
  background-color: whitesmoke;
  overflow-x: hidden;
  margin: 10px !important;
  padding: 20px;
}

.process-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.process-step {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  margin-right: 40px;
}

.process-icon {
  background-color: #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.process-arrow {
  flex-grow: 1;
  height: 2px;
  background-color: #007bff;
  position: relative;
}



/* Ajustement pour les écrans de plus de 2000px */
@media (min-width: 2000px) {
  .hero-title {
    font-size: 5rem;
  }
  .sous-titre {
    font-size: 4rem;
    top: calc(50% + 5rem);
  }
  /* Ajustez d'autres éléments si nécessaire */
}

/* Ajustement pour les petites résolutions */
@media (max-width: 768px) {
  .rectangle02, .rectangle02.reversed {
    flex-direction: column;
    text-align: center;
  }
  .rectangle02 .text-container, .rectangle02.reversed .text-container {
    max-width: 100%;
  }
  .stats {
    flex-direction: column;
  }
  .process-steps-container {
    flex-direction: column;
  }
  .process-arrow {
    width: 2px;
    height: 50px;
    margin-bottom: 20px;
  }
  .white-sectiona1{
    flex-direction: column;
    min-height: 1500px;
  }
  .boxa1 {
    max-width: 400px;
  }
  .boxa2 {
    max-width: 400px;
  }
  .boxa3 {
    max-width: 400px;
  }
 
}
